import store from '@/state/store'

export default [{
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {

        console.log('connecte: ' + store.getters['authfack/loggedIn']);
        // If the user is already logged in
        if (store.getters['authfack/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch('auth/logOut')
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? {
          name: 'home'
        } : {
          ...routeFrom
        })
      },
    },
  }, {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/contacts/list')
  },
  {
    path: '/restrictedAccess',
    name: 'restrictedAccess',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/restricted/index')
  },
  {
    path: '/migration',
    name: 'migration',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/migration/migration')
  },
  {
    path: '/food',
    name: 'food',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/food/food')
  },
  {
    path: '/community',
    name: 'community',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/community/community')
  },
  {
    path: '/snella',
    name: 'snella',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/snella/snella')
  },
  {
    path: '/coupons',
    name: 'coupons',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/coupons/coupon')
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/notifications/notifications')
  },
  {
    path: '/recettes',
    name: 'recettes',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/recettes/recettes')
  },
  {
    path: '/exercices',
    name: 'exercices',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/exercices/exercices')
  },
  {
    path: '/basicworkouts',
    name: 'basicworkouts',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/basicworkouts/basicworkouts')
  },
  {
    path: '/basicprograms',
    name: 'basicprograms',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/basicprograms/basicprograms')
  },
  {
    path: '/videos',
    name: 'videos',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/videos/videos')
  },
  {
    path: '/tickets',
    name: 'tickets',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/tickets/tickets')
  },
  {
    path: '/chat',
    name: 'chat',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/chat/index')
  },
  {
    path: '/ecommerce/products',
    name: 'Products',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ecommerce/products')
  },
  {
    path: '/ecommerce/product-detail/:id',
    name: 'Product Detail',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ecommerce/product-detail')
  },
  {
    path: '/ecommerce/orders',
    name: 'Orders',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ecommerce/orders')
  },
  {
    path: '/assign',
    name: 'Assign',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/assign/assign')
  },
  {
    path: '/alert',
    name: 'Alert',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/alert/alert')
  },
  {
    path: '/revenue',
    name: 'Revenue',
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    component: () => import('../views/pages/revenue/revenue')
  },
  {
    path: '/planning',
    name: 'Planning',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/planning/planning')
  },
  {
    path: '/contacts/new',
    name: 'NewUser',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/contacts/new')
  },
  {
    path: '/contacts/list',
    name: 'User List',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/contacts/list')
  },
  {
    path: '/contacts/profile/:id',
    name: 'Profile',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/contacts/profile')
  },
  {
    path: '/premiumprogram/new/:iduser/:idcoach',
    name: 'Premiumprogramnew',
    /*meta: {
      authRequired: true
    },*/
    component: () => import('../views/pages/premiumprograms/premiumprograms')
  },
  {
    path: '/premiumprogram/edit/:idprogram',
    name: 'Premiumprogramedit',
    /*meta: {
      authRequired: true
    },*/
    component: () => import('../views/pages/premiumprograms/premiumprograms')
  },
]