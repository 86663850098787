import { authHeader } from './auth-header';
//import axios from 'axios';
//import jwt_decode from "jwt-decode";

export const userService = {
    login,
    logout,
    getAll,
};

function login(email, password) {

    // Simple POST request with a JSON body using axios
    //const infoslogin = { "username" : email, "password": password };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "username" : email, "password": password })
    };

    return fetch(`https://gabriellavico.coach/wp-json/jwt-auth/v1/token`, requestOptions)
        .then(handleResponse)
        .then(user => {
            var roles = user.roles;
            // login successful if there's a jwt token in the response
            if (user.token && Object.values(roles).includes("coach")) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });


        /*
    return axios.post("https://gabriellavico.coach/wp-json/jwt-auth/v1/token", infoslogin)
    .then(user => {

        var roles = user.data.roles;
        if(Object.values(roles).includes("coach")){
            // login successful if there's a jwt token in the response
            if (user.data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        }
        else return Promise.reject('No Coach');
            
        });*/
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text)
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
