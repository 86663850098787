import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import axios from 'axios';

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'

Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

import { initFirebaseBackend } from './helpers/firebase/authUtils';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
}

//import { configureFakeBackend } from './helpers/fakebackend/fake-backend';
//configureFakeBackend();

Vue.mixin({
  data: function () {
    return {
      get isAdmin() { 
        return sessionStorage.getItem('isAdmin');
      }
    }
  }
})

import '@/assets/scss/app.scss'

Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(require('vue-moment'));

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

//AJOUTER UNE EXCEPTION ICI POUR LA REQUETE DE LOGIN
axios.interceptors.request.use(config => {
  if (config.url.includes('gabriellavico.coach')) {
    var user = localStorage.getItem('user2');
    var token = JSON.parse(user).token;
    config.headers["Authorization"] = 'Bearer ' + token;
  }
  return config;
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
